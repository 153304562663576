import * as React from "react"
import styled, { keyframes } from "styled-components"
import { graphql, Link } from "gatsby"
import Fade from "react-reveal/Fade"
import { ParallaxProvider, Parallax } from "react-scroll-parallax"
import Layout from "../components/layout"
import get from "lodash/get"
import debounce from "lodash/debounce"
import { IoStar } from "@react-icons/all-files/io5/IoStar"
import { IoStarOutline } from "@react-icons/all-files/io5/IoStarOutline"
import { IoSearchOutline } from "@react-icons/all-files/io5/IoSearchOutline"
import { IoCloseOutline } from "@react-icons/all-files/io5/IoCloseOutline"
import { useLocalStorage } from "react-use"
import Helmet from "react-helmet"
import ribbonMaskImage from "../images/ribbon-mask.png"

const Content = styled.div`
  position: relative;
  @media (min-width: 1000px) {
    padding-top: 5em;
  }
`

const Section = styled.div`
  background: #101010;
`

const SectionContent = styled.div`
  max-width: 80em;
  margin: 0 auto;
  display: flex;
  padding: 0 0 5em 0;
  flex-direction: column;
  @media (min-width: 1000px) {
    & > * {
      width: 25%;
    }
  }
`

const buttonFlash = keyframes`
  0% {
    background: #fff;
  }
  100% {
    background: transparent;
  }
`

const Button = styled.a`
  display: flex;
  border: 1px solid #fb578a;
  color: #fb578a;
  padding: 1em;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.9em;
  font-weight: 400;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  &:hover {
    background: transparent;
    animation: ${buttonFlash} 250ms ease-out;
    border-color: #fff;
    cursor: pointer;
    color: #fff;
  }
`

const FavoriteButton = styled(Button)`
  margin-top: 1.3em;
`

const ModelList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  & > * {
    width: 50%;
  }
  @media (min-width: 1000px) {
    & > * {
      width: 25%;
    }
  }
`

const ModelInfoOuter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const ModelInfoWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 1em 0.5em;
  background: rgba(0,0,0,0.7);
  color: #fff;
  transition: all 400ms ease-in-out;
  transform: translateY(calc(100% - 4em));
  font-size: 0.8em;
  @media (min-width: 1000px) {
    padding: 1em;
    font-size: 1em;
  }
`

const FavoriteIcon = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  background: transparent;
  padding: 0.9em;
  font-size: 1.35em;
  cursor: pointer;
  appearance: none;
  border: none;
`

const ModelWrapper = styled(Link)`
  display: flex;
  flex-direction: column;
  margin: 0.25em;
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      filter: brightness(1.1);
      ${ModelInfoWrapper} {
        transform: translateY(0);
      }
    }
  }
  @media (min-width: 1000px) {
    margin: 1em;
  }
`

const ModelImageWrapper = styled.div`
  width: 100%;
  padding-top: 166.66%;
  position: relative;
`

const ModelImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const ModelSpecialBookingLabel = styled.div`
  position: absolute;
  top: 1em;
  left: -0.2em;
  padding: 0.2em 1em 0.3em 0.5em;
  font-size: 0.9em;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: #fb578a;
  color: #ffffff;
  -webkit-mask-image: url(${ribbonMaskImage});
  -webkit-mask-size: 100% 100%;
  -webkit-mask-position: right center;
  mask-image: url(${ribbonMaskImage});
  mask-size: 100% 100%;
  mask-position: right center;
  -webkit-filter: drop-shadow(1px 4px 0px rgba(0,0,0,0.5));
  -moz-filter: drop-shadow(1px 4px 0px rgba(0,0,0,0.5));
  -ms-filter: drop-shadow(1px 4px 0px rgba(0,0,0,0.5));
  -o-filter: drop-shadow(1px 4px 0px rgba(0,0,0,0.5));
`

const ModelCategories = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5em;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 0.5em 4em 0.5em 0.5em;
  background: rgba(16,16,16,0.8);
  backdrop-filter: blur(20px);
  @media (min-width: 1000px) {
    margin-top: 1em;
    top: 5em;
    padding: 0;
    justify-content: center;
  }
`

const ModelCategory = styled.a`
  text-transform: uppercase;
  display: block;
  padding: 0.5em;
  font-size: 1em;
  color: ${({active}) => active ? "#fff" : "#909090"};
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  @media (hover: hover) {
    &:hover {
      color: #fff;
    }
  }
  @media (min-width: 1000px) {
    padding: 1em;
  }
`

const ModelName = styled.div`
  font-size: 1.5em;
  text-align: center;
  font-weight: 200;
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  margin-bottom: 0.5em;
`

const ModelInfoRow = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(255,255,255,0.2);
  padding: 0.5em;
  &:last-child {
    border-bottom: none;
  }
`

const ModelInfoLabel = styled.div`
  flex: 1;
  color: #a0a0a0;
`

const ModelInfoValue = styled.div`
  flex: 2;
  text-transform: uppercase;
`

const SearchInputWrapper = styled.div`
  position: relative;
  width: 100%;
  padding: 0 1em;
  box-sizing: border-box;
  margin: 1em auto;
  @media (min-width: 1000px) {
    max-width: 20em;
  }
`

const SearchInputIcon = styled(IoSearchOutline)`
  position: absolute;
  top: 0.3em;
  right: 0.9em;
  color: #808080;
  font-size: 1.5em;
  pointer-events: none;
`

const SearchInputClearButton = styled(IoCloseOutline)`
  position: absolute;
  top: 0.3em;
  right: 0.3em;
  color: #808080;
  font-size: 1.5em;
  cursor: pointer;
  &:hover {
    color: #fff;
  }
`

const SearchInput = styled.input`
  padding: 0.3em 0.5em;
  font-size: 1.2em;
  font-family: inherit;
  font-weight: 300;
  border: none;
  background: rgba(255,255,255,0.05);
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  display: block;
  &:focus {
    outline: 1px solid #808080;
  }
  ::placeholder,
  ::-webkit-input-placeholder {
    color: #808080;
  }
  :-ms-input-placeholder {
     color: #808080;
  }
`

const FavoritePlaceholder = styled.div`
  font-size: 1.5em;
  text-align: center;
  padding: 3em 1em;
  width: 100%;
  box-sizing: border-box;
  font-weight: 300;
  color: #808080;
`

const categories = ["naiset", "miehet", "mainosmallit", "30plus", "nuoret", "lapset"]

// markup
const Models = ({data}) => {
  const models = get(data, "allContentfulModel.edges")
  const modelIdExists = (id) => models.some((({node}) => node.id === id))

  const hasWindow = (typeof window !== "undefined")
  const initialFavorites = hasWindow ? window.location.hash.replace("#", "").split("+").filter(modelIdExists) : []
  
  //const initialCategory = (initialFavorites.length > 0) ? "favorites" : categories[0]

  const [category, setCategoryRaw] = useLocalStorage("categories", categories[0])
  const [filterName, setFilterName] = useLocalStorage("filterName", "")
  const [favorites, setFavorites] = useLocalStorage("favorites", initialFavorites)
  const [filterNameInputValue, setFilterNameInputValue] = React.useState(filterName || "")

  const setFilterNameDebounced = debounce(setFilterName, 400)
  const setCategory = (c) => {
    setCategoryRaw(c)
    if(hasWindow) {
      if (c === "favorites") {
        window.location.hash = "#" + favorites.join("+")
      }
      else {
        window.location.hash = ""
      }
    }
    setTimeout(() => {
      if (hasWindow) { window.scrollTo({top: 0}) }
    }, 0)
  }

  const toggleFavorite = (id) => {
    const newFavorites = ((favorites.indexOf(id) !== -1)
      ? favorites.filter(f => f !== id)
      : [...favorites, id]
    ).filter(modelIdExists)
    
    if (hasWindow && category === "favorites") {
      window.location.hash = "#" + newFavorites.join("+")
    }
    
    setFavorites(newFavorites)
  }
  const isFavorite = (id) => {
    return favorites.indexOf(id) !== -1
  }

  const filterFn = ({node}) => {
    if(!node.coverImage && !node.images) {
      return false
    }
    if(category === "favorites" && isFavorite(node.id)) {
      return true
    }
    if(node.category !== category) {
      return false
    }
    if(filterName.trim() !== "") {
      return node.name.toLowerCase().indexOf(filterName.trim().toLowerCase()) !== -1
    }
    return true;
  }

  const filteredModels = models.filter(filterFn)

  return (
    <ParallaxProvider>
      <Helmet>
        <title>Mallit</title>
      </Helmet>
      <Layout>
        <Content>
          <Section>
            <ModelCategories>
              {categories.map(c => {
                return <ModelCategory href="#" active={(c === category)} onClick={(e) => {e.preventDefault(); setCategory(c)}}>
                  {c}
                </ModelCategory>
              })}
              <ModelCategory href="#" active={(category === "favorites")} onClick={(e) => {e.preventDefault(); setCategory("favorites")}}>
                <IoStar /> Suosikit
              </ModelCategory>
            </ModelCategories>
            <SectionContent>
              {category !== "favorites" && <SearchInputWrapper>
                <SearchInput type="text" value={filterNameInputValue} onChange={(e) => {setFilterNameInputValue(e.target.value); setFilterNameDebounced(e.target.value)}} placeholder="Hae malleja nimellä..." />
                {filterName.length === 0 && <SearchInputIcon />}
                {filterName.length > 0 && <SearchInputClearButton onClick={(e) => {setFilterNameInputValue(""); setFilterName("")}} />}
              </SearchInputWrapper>}
              {category === "favorites" && filteredModels.length === 0 && <FavoritePlaceholder>Et ole vielä valinnut yhtäkään suosikkia.</FavoritePlaceholder>}
              <ModelList>
                {filteredModels.map(({node}, i) => {
                  const image = node.coverImage || node.images[0]
                  return <Fade key={node.id + category + filterName} bottom delay={((i % 4) * 100)}>
                    <ModelWrapper to={`/mallit/${node.category}/${node.slug}`}>
                      <ModelImageWrapper>
                        <ModelImage src={image.fixed.src} />
                        {node.specialBooking && <ModelSpecialBookingLabel>Special booking</ModelSpecialBookingLabel>}
                        <ModelInfoOuter>
                          <ModelInfoWrapper>
                            <ModelName>{node.name}</ModelName>
                            <div>
                              {node.height && <ModelInfoRow>
                                <ModelInfoLabel>Pituus</ModelInfoLabel>
                                <ModelInfoValue>{node.height}</ModelInfoValue>
                              </ModelInfoRow>}
                              {node.size && <ModelInfoRow>
                                <ModelInfoLabel>Koko</ModelInfoLabel>
                                <ModelInfoValue>{node.size}</ModelInfoValue>
                              </ModelInfoRow>}
                              {node.eyes && <ModelInfoRow>
                                <ModelInfoLabel>Silmät</ModelInfoLabel>
                                <ModelInfoValue>{node.eyes}</ModelInfoValue>
                              </ModelInfoRow>}
                              {node.hair && <ModelInfoRow>
                                <ModelInfoLabel>Hiukset</ModelInfoLabel>
                                <ModelInfoValue>{node.hair}</ModelInfoValue>
                              </ModelInfoRow>}
                              {node.foot && <ModelInfoRow>
                                <ModelInfoLabel>Kenkä</ModelInfoLabel>
                                <ModelInfoValue>{node.foot}</ModelInfoValue>
                              </ModelInfoRow>}
                            </div>
                            <FavoriteButton onClick={(e) => {e.stopPropagation(); e.preventDefault(); toggleFavorite(node.id)}}>
                              {!isFavorite(node.id)
                              ? <><IoStarOutline /> Lisää suosikkeihin</>
                              : <><IoStar /> Poista suosikeista</>}
                            </FavoriteButton>
                            {isFavorite(node.id) && <FavoriteIcon onClick={() => toggleFavorite(node.id)}>
                              <IoStar />
                            </FavoriteIcon>}
                          </ModelInfoWrapper>
                        </ModelInfoOuter>
                      </ModelImageWrapper>
                    </ModelWrapper>
                  </Fade>
                })}
              </ModelList>
            </SectionContent>
          </Section>
        </Content>
      </Layout>
    </ParallaxProvider>
  )
}

export default Models

export const pageQuery = graphql`
  query ModelsQuery {
    allContentfulModel(sort: {fields: [gender, name], order: [ASC, ASC]}) {
      edges {
        node {
          id
          slug
          name
          category
          height
          eyes
          size
          foot
          hair
          images {
            fixed(width: 380, quality: 100) {
              src
            }
          }
          coverImage {
            ... on ContentfulAsset {
              fixed(width: 380, quality: 100) {
                src
              }
            }
          }
          gender
          specialBooking
        }
      }
    }
  }
`
